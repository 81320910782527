/* ========== Form-style ======== */
.form-style {
  .ant-form-item-label > label {
    font-size: 16px;
    &::after {
      content: '';
    }
  }
}
.input-control {
  height: 38px !important;
  border-radius: 3px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  background-color: white !important;
}
.ant-input-affix-wrapper .input-control {
  background: white !important;
}
.input-control-number {
  height: 38px !important;
  border-radius: 3px !important;
  width: 100%;
}
.ant-input-number-input {
  height: 35px;
}

/* ========== Select ======== */
.css-1s2u09g-control,
.css-1tibjvx-control {
  border-color: #d9d9d9 !important;
  border-radius: 3px !important;
}
.css-1uama3p-control {
  border-right-width: 1px;
}
.css-1uama3p-control:hover {
  border-color: #c180ff !important;
}
.css-1okebmr-indicatorSeparator {
  display: none !important;
}
.css-14el2xx-placeholder {
  color: #c0c1c4 !important;
}

/* ========== drag table ======== */
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}
.row-dragging td {
  padding: 16px;
}
.row-dragging .drag-visible {
  visibility: visible;
}

/* ========== Button ======== */
.ant-btn {
  height: 43px !important;
  font-size: 16px !important;
  border-radius: 4px !important;
  &:active {
    border-color: none !important;
  }
}
.ant-btn-primary[disabled] {
  background: #9f52f4 !important;
  color: white;
  border: 0;
  &:hover {
    color: white;
  }
}
.ant-btn-primary:focus,
.ant-btn-primary:hover,
.ant-btn-primary:active {
  border-color: #9f52f4 !important;
  background: #9f52f4 !important;
}

/* ========== Table ======== */

.ant-table-column-title,
th {
  font-weight: 600;
}
.ant-table-thead tr th {
  font-weight: 500px !important;
}
.ant-table-column-sorter {
  display: none;
}
.ant-table-thead > tr > th {
  font-weight: 600 !important;
}
.table-cover-img {
  width: 90px;
  height: 80px;
  border-radius: 2px;
  object-fit: cover;
}
.fix-table {
  min-width: 100%;
  max-width: 100%;
  width: 1120px;
  overflow: auto;
}
.ant-table-column-title {
  z-index: unset;
}
.small-table {
  width: unset !important;
}

@media (max-width: 1120px) {
  .ant-table-wrapper {
    max-width: 1120px;
    width: 1120px;
  }
  .width-1120 {
    width: 1120px;
  }
  .small-table {
    width: unset;
  }
}

/* ========== clear-all ======== */
.clear-all {
  color: #9f52f4;
  text-decoration: underline;
  font-size: 16px;
  cursor: pointer;
  display: inline;
  margin: 0;
}

/* ========== Date ======== */
.ant-picker-ok .ant-btn {
  width: 100px;
  height: 42px !important;
}

/* ========= ant-modal-footer =========== */

.ant-modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-btn {
    width: 100px;
  }
}

/* ========== Pagination  ======== */
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 37px !important;
  justify-content: center;
  align-items: center;
}

/* ==== Spinner ==== */

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: white;
  opacity: 0.6;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ========== Scroll Bar ============ */

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #e4e9f2;
}
::-webkit-scrollbar-thumb:hover {
  background: #e4e9f2;
}

/* ========== Drawer ============ */

.ant-drawer-content-wrapper {
  width: 450px !important;
}

@media (max-width: 500px) {
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}
