.search-section {
  div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__btn-group {
    width: 60%;
    margin-left: 20px;
  }
  &__select {
    width: 200px;
    max-width: 100%;
  }
}
@media screen and (max-width: 850px) {
  .search-section {
    div {
      flex-direction: column;
    }
    &__btn-group {
      width: 100%;
      flex-direction: row !important;
      justify-content: start !important;
      flex-wrap: wrap;
      margin: 0;
      button {
        margin-top: 10px;
        margin-right: 10px;
      }
    }
    &__select {
      margin-top: 10px;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .main-search div {
    flex-direction: column;
  }
  .btnCount-3 {
    width: 100% !important;
    flex-direction: row !important;
    justify-content: start !important;
    flex-wrap: wrap;
    margin: 0;
    button {
      margin-top: 10px;
      margin-right: 10px;
    }
  }
}

.add-edit-form {
  width: 600px;
  max-width: 100%;
  margin: auto;
  padding: 30px !important;
  position: relative;
  form {
    margin-top: 30px !important;
    .ant-form-item {
      margin-bottom: 25px !important;
    }
    .ant-form-item-label {
      width: 28%;
      text-align: start;
      margin-right: 15px;
      label {
        font-size: 16px !important;
        &::after {
          content: none;
        }
      }
    }
  }
}
.form-permission-table {
  flex-direction: column !important;
}
.form-btn-group {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  flex-wrap: wrap;
  .half-width {
    width: 200px !important;
  }
}

.form-loading-spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  background: rgba(245, 245, 245, 0.494);
}
.info-slider {
  padding: 10px;
  text-align: center;
  img {
    width: 150px;
    height: 100px;
    border-radius: 5px;
    margin-bottom: 25px;
    object-fit: contain;
  }
  &__dash {
    width: 10% !important;
    font-weight: 600;
  }
  &__row-span {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    text-align: start;
    align-items: flex-start;
    margin-bottom: 5px;
    h4 {
      opacity: 0.8;
      border-bottom: 0;
      width: 40% !important;
      font-weight: 600;
      margin-right: 10px;
    }

    h3 {
      width: 50% !important;
      font-weight: 400;
    }
  }
}

.img-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .img-wrapper {
    width: 40%;
    margin-left: 10px;
    .img {
      position: relative;
      .card-inside-img {
        position: absolute;
        top: 56px;
        left: 18px;
        width: 263px;
        height: 234px;
        background-color: #e1e1e1;
        padding: 5px;
        overflow: auto;
        .card-info {
          h5 {
            margin-bottom: 0;
          }
          p {
            line-height: 14px;
            margin-bottom: 0;
            color: #787878;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1150px) {
  .img-form {
    justify-content: center;
    flex-direction: column;
    .img-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0;
      margin-top: 20px;
    }
  }
}
