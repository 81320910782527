/* ====== Background ===== */

.bg-white {
  background-color: #fff;
}
.bg-light-blue {
  background-color: #edf1f7 !important;
}
.bg-sky-blue {
  background-color: #68d0e8 !important;
}
.bg-blue {
  background-color: #4169d7;
}
.bg-light-gray {
  background-color: #f2f2f2;
}
.bg-light-gray2 {
  background-color: #f2f2f2;
}
.bg-dark-blue {
  background-color: #4169d7be !important;
}
.bg-moon-mist {
  background-color: #cdc2b8;
}

/* ====== font-color ===== */

.text-biscay {
  color: #466080 !important;
}
.text-blue {
  color: #3d70ff !important;
}
.text-gray {
  color: #edf1f7;
}
.text-white {
  color: white !important;
}
.text-light-gray {
  color: #6c757d !important;
}
.text-black {
  color: #000 !important;
}

/* ====== hover ===== */

.hover-text-white:hover {
  color: white;
}

/* ====== text decoration ===== */

.text-decoration-underline {
  text-decoration: underline;
}

/* ====== font-size ===== */

.font-size-12 {
  font-size: 12px;
}
.font-size-13 {
  font-size: 13px;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-15 {
  font-size: 15px;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-18 {
  font-size: 18px !important;
}
.font-size-20 {
  font-size: 20px;
}
.font-size-25 {
  font-size: 25px;
}
.font-size-28 {
  font-size: 28px;
}
.font-size-50 {
  font-size: 50px;
}

/* ====== font-weight ===== */

.font-weight-400 {
  font-weight: 400 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.font-weight-700 {
  font-weight: 700;
}

/* ====== Margin ===== */

.m-20 {
  margin: 20px;
}
.mt-1 {
  margin-top: 10px;
}
.mt-2 {
  margin-top: 20px;
}
.mt-3 {
  margin-top: 30px;
}
.mt-4 {
  margin-top: 40px !important;
}
.mt-5 {
  margin-top: 50px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 10px !important;
}
.mb-2 {
  margin-bottom: 20px !important;
}
.mb-3 {
  margin-bottom: 30px !important;
}
.mb-4 {
  margin-bottom: 40px !important;
}
.mb-5 {
  margin-bottom: 50px !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.ml-1 {
  margin-left: 10px;
}
.ml-2 {
  margin-left: 20px;
}
.ml-3 {
  margin-left: 30px;
}
.ml-4 {
  margin-left: 40px;
}
.ml-5 {
  margin-left: 50px;
}

.mr-1 {
  margin-right: 10px;
}
.mr-2 {
  margin-right: 20px !important;
}
.mr-3 {
  margin-right: 30px;
}
.mr-4 {
  margin-right: 40px;
}
.mr-5 {
  margin-right: 50px;
}

.mx-1 {
  margin-left: 10px;
  margin-right: 10px;
}
.mx-2 {
  margin-left: 20px;
  margin-right: 20px;
}
.mx-3 {
  margin-left: 30px;
  margin-right: 30px;
}
.mx-4 {
  margin-left: 40px;
  margin-right: 40px;
}
.mx-5 {
  margin-left: 50px;
  margin-right: 50px;
}

.my-1 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.my-2 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.my-3 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.my-4 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.my-5 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mx-auto {
  margin-left: auto;
  margin-left: auto;
}

/* ====== padding ===== */

.p-30 {
  padding: 30px;
}
.p-0 {
  padding: 0;
}
.p-1 {
  padding: 10px;
}
.p-2 {
  padding: 20px;
}
.p-3 {
  padding: 30px;
}
.p-4 {
  padding: 40px;
}
.p-5 {
  padding: 50px;
}

.pt-1 {
  padding-top: 10px;
}
.pt-2 {
  padding-top: 20px;
}
.pt-3 {
  padding-top: 30px;
}
.pt-4 {
  padding-top: 40px;
}
.pt-5 {
  padding-top: 50px;
}

.pl-1 {
  padding-left: 10px !important;
}
.pl-2 {
  padding-left: 20px !important;
}
.pl-3 {
  padding-left: 30px !important;
}
.pl-4 {
  padding-left: 40px !important;
}
.pl-5 {
  padding-left: 50px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 10px !important;
}
.pb-2 {
  padding-bottom: 20px;
}
.pb-3 {
  padding-bottom: 30px;
}
.pb-4 {
  padding-bottom: 40px;
}
.pb-5 {
  padding-bottom: 50px;
}

.px-1 {
  padding-left: 10px;
  padding-right: 10px;
}
.px-2 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.px-3 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.px-4 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.px-5 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.py-1 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.py-2 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.py-3 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.py-4 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.py-5 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.py-8px {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.py-5px {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

/* ====== text-align ===== */

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-end {
  text-align: end;
}

/* ====== list-style ===== */

.list-style-auto {
  list-style: auto;
}

/* ====== border-radius ===== */

.border-radius-3 {
  border-radius: 3px;
}
.border-radius-5 {
  border-radius: 5px !important;
}
.border-radius-10 {
  border-radius: 10px;
}

/* ====== border ===== */

.border-0 {
  border: 0;
}
.border-gray-1 {
  border: 1px solid #edf1f7;
}
.border-blue-1 {
  border: 1px solid #4169d7;
}

/* ====== box-shadow ===== */

.box-shadow-1 {
  box-shadow: 0px 4px 10px rgb(0 0 0 / 3%), 0px 0px 2px rgb(0 0 0 / 6%),
    0px 2px 6px rgb(0 0 0 / 12%) !important;
}
.box-shadow-2 {
  box-shadow: 0 0.5rem 1rem 0 rgb(44 51 73 / 10%);
}
.box-shadow-3 {
  box-shadow: 0px 4px 10px rgb(0 0 0 / 3%), 0px 0px 2px rgb(0 0 0 / 6%),
    0px 2px 6px rgb(0 0 0 / 12%) !important;
}

/* ====== width ===== */

.width-150 {
  width: 150px !important;
}
.width-180 {
  width: 180px !important;
}
.width-250 {
  width: 250px !important;
}
.width-200 {
  width: 200px !important;
}
.w-100 {
  width: 100% !important;
}

@media (max-width: 500px) {
  .width-250 {
    width: 100% !important;
  }
}

/* ====== height ===== */

.h-100 {
  height: 100vh !important;
}
.h-70 {
  height: 70vh;
}
.h-200 {
  height: 200px;
}
.h-25px {
  height: 25px;
}
.h-min-212 {
  height: 212px;
  min-height: 212px;
}

/* ====== flex ===== */

.d-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-end {
  justify-content: end;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: end;
}
.justify-content-between {
  justify-content: space-between !important;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-direction-column {
  flex-direction: column;
}

@media (max-width: 991px) {
  .justify-content-md-left {
    justify-content: left !important;
  }
}

/* ====== Display ===== */
.d-unset {
  display: unset !important;
}
.d-inline {
  display: inline;
}

/* ====== line-height ===== */

.line-height-6 {
  line-height: 6px;
}
.line-height-14 {
  line-height: 14px;
}
.line-height-16 {
  line-height: 16px;
}

/* ====== cursor ===== */

.cursor-pointer {
  cursor: pointer;
}

/* ====== work-break ===== */
.word-break-all {
  word-break: break-all;
}

/* ====== Remove from small screen ===== */

@media (max-width: 767px) {
  .d-sm-none {
    display: none;
  }
  .text-xs-left {
    text-align: left !important;
  }
}
