@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,700;1,300&display=swap');

* {
  box-sizing: border-box;
}

:root {
  --body-font-family: 'Poppins', sans-serif;
  --font-color-primary: #222b45;
  --font-color-secondary: #495057;
  --font-weight-labels: 600;
  --main-color: #4169d7;
  --input-background: #edf1f7;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: var(--body-font-family);
  color: var(--font-color-primary);
  font-size: 16px;
}

.anticon .anticon-close {
  background: transparent !important;
}

.anticon .anticon-close:hover {
  background: transparent !important;
}

.ant-modal-close:hover {
  background: transparent !important;
  border: none;
  outline: none;
  color: var(--font-color-primary) !important;
}

.ant-drawer-close:hover {
  background: transparent !important;
  border: none;
  outline: none;
  color: var(--font-color-primary) !important;
}

ul {
  margin: 0;
  padding: 0;
}
